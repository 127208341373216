<template>
  <div class="team">
    <div class="action-bar">
      <router-link to="/team/create">
        <el-button type="primary" icon="el-icon-edit">新增团队成员</el-button>
      </router-link>
      <el-input v-model="keyword" placeholder="输入关键字搜索团队成员信息" class="search" @keyup.enter.native="search">
        <el-button icon="el-icon-search" @click="search" slot="append"></el-button>
      </el-input>
    </div>
    <el-card shadow="hover">
      <el-table :data="list" size="mini" v-loading="loading">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div v-html="scope.row.content"></div>
          </template>
        </el-table-column>
        <el-table-column label="ID" prop="id" width="80"></el-table-column>
        <el-table-column label="排序" width="80">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sort" @change="sortChange(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="照片" width="100px">
          <template slot-scope="scope">
            <a :href="scope.row.image_url" target="_blank">
              <img :src="scope.row.image_url" alt="照片" style="width: 50px;">
            </a>
          </template>
        </el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="职位" prop="title"></el-table-column>
        <el-table-column label="创建时间" prop="created_at" width="150"></el-table-column>
        <el-table-column label="最后更新" prop="updated_at" width="150"></el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" type="success" v-if="scope.row.state">已发布</el-tag>
            <el-tag size="mini" type="info" v-else>未发布</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <router-link :to="{ name: 'teamEdit', query: { id: scope.row.id } }">
              <el-button type="primary" size="mini">编辑</el-button>
            </router-link>
            <el-button type="danger" size="mini" @click="deleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { teamDelete, teamList, teamSort } from '@/request/modules/team'
export default {
  data () {
    return {
      list: [],
      loading: false,
      keyword: '',
      sorts: {}
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      teamList({ keyword: this.keyword, page: this.page }).then(resp => {
        this.list = resp
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    search () {
      this.currentChange(1)
    },
    deleteItem (row) {
      this.$confirm(`确定删除${row.name}?`, {}).then(() => {
        teamDelete({ id: row.id }).then(resp => {
          this.$message.success('操作成功!')
          this.fetchData()
        }).catch(() => {})
      })
    },
    sortChange (e) {
      const { id, sort } = e
      teamSort({ id, sort }).then(resp => {
        this.fetchData()
      }).catch(() => {})
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
